import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import './styles.scss';

const Row = ({
  children,
  className,
  verticalAlignCenter,
  verticalAlignBottom,
  verticalAlignTop,
  withDividers,
  ...other
}) => {
  const getClassNames = () =>
    classNames(
      'row',
      verticalAlignCenter && 'row--vertical-align-center',
      verticalAlignBottom && 'row--vertical-align-bottom',
      verticalAlignTop && 'row--vertical-align-top',
      withDividers && 'row--with-dividers',
      className
    );

  return (
    <div className={getClassNames()} {...other}>
      {children}
    </div>
  );
};

Row.defaultProps = {
  className: null,
};

Row.propTypes = {
  /** A custom class name */
  className: PropTypes.string,
  /** Vertically align columns to the center of the row */
  verticalAlignCenter: PropTypes.bool,
  /** Vertically align columns to the bottom of the row */
  verticalAlignBottom: PropTypes.bool,
  /** Vertically align columns to the top of the row */
  verticalAlignTop: PropTypes.bool,
  /** Add divding lines between columns */
  withDividers: PropTypes.bool,
};

export default Row;
