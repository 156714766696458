import React from 'react';
import OutboundLink from '../OutboundLink';
import { TextInput, Checkbox, Select } from '../Form';
import roles from './roles';
import { months, years } from './birthday';

const schema = {
  email: {
    label: 'Email',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  inGradesKto3: {
    label: 'K–3',
    component: Checkbox,
    checked: false,
  },
  inGrades4to5: {
    label: '4–5',
    component: Checkbox,
    checked: false,
  },
  inGrades6to8: {
    label: '6–8',
    component: Checkbox,
    checked: false,
  },
  inGrades9to12: {
    label: '9–12',
    component: Checkbox,
    checked: false,
  },
  interestedInSTEM: {
    label: 'STEM',
    component: Checkbox,
    checked: false,
  },
  interestedInLanguageArts: {
    label: 'Language Arts',
    component: Checkbox,
    checked: false,
  },
  interestedInEducation: {
    label: 'Health',
    component: Checkbox,
    checked: false,
  },
  interestedInVirtualFieldTrips: {
    label: 'Virtual Field Trips',
    component: Checkbox,
    checked: false,
  },
  interestedInSweepstakesAndChallenges: {
    label: 'Sweepstakes and Contests',
    component: Checkbox,
    checked: false,
  },
  role: {
    label: 'Role',
    component: Select,
    placeholder: 'Select',
    options: roles,
    value: '',
    required: false,
    labelHidden: false,
  },
  month: {
    label: 'Month',
    component: Select,
    placeholder: 'Month',
    options: months,
    value: '',
    required: true,
    labelHidden: true,
  },
  year: {
    label: 'Year',
    component: Select,
    placeholder: 'Year',
    options: years,
    value: '',
    required: true,
    labelHidden: true,
  },
  subscribeToDE: {
    label: (
      <>
        Stay up-to-date with service enhancements, new content, professional
        development opportunities, special events, education program and news
        from Discovery Education in accordance with the following{' '}
        <a
          href="https://www.discoveryeducation.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: (programName, partnerPrivacyUrl) => (
      <>
        I would like to receive information about {programName} initiatives in
        accordance with the following{' '}
        <OutboundLink to={partnerPrivacyUrl}>Privacy Policy</OutboundLink>.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
};

export default schema;
